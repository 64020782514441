// src/Components/Banner/Banner.js
import React, { useState } from 'react';
import './Banner.css';
import NewsletterPopup from '../NewsletterPopup/NewsletterPopup'; // Adjusted import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWineGlassAlt, faBeer } from '@fortawesome/free-solid-svg-icons';

const Banner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBannerClick = () => {
    setIsModalOpen(true); // Open the newsletter popup
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the popup
  };

  return (
    <>
      <div
        className="banner"
        onClick={handleBannerClick}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleBannerClick();
        }}
      >
        <div className="ticker-container">
          <div className="ticker">
            {/* Grape (wine glass) and Beer Icons */}
            <FontAwesomeIcon icon={faWineGlassAlt} className="icon grape-icon" />
            <h1>Website Under Construction!</h1>
            <FontAwesomeIcon icon={faBeer} className="icon beer-icon" />
            <FontAwesomeIcon icon={faWineGlassAlt} className="icon grape-icon" />
            <h1>Website Under Construction!</h1>
            <FontAwesomeIcon icon={faBeer} className="icon beer-icon" />
            <FontAwesomeIcon icon={faWineGlassAlt} className="icon grape-icon" />
            <h1>Website Under Construction!</h1>
            <FontAwesomeIcon icon={faBeer} className="icon beer-icon" />
          </div>
        </div>
      </div>
      <NewsletterPopup isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Banner;
