// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from '../Pages/HomePage/HomePage';
import ShopPage from '../Pages/ShopPage/ShopPage';
import ResourcePage from '../Pages/ResourcePage/ResourcePage';
import OnboardingWizard from '../Pages/OnboardingWizard/OnboardingWizard';
import UserProfile from '../Pages/ProfilePage/UserProfile';
import AuthModal from '../Components/AuthModal/AuthModal';
import Navbar from '../Components/Navbar/Navbar';
import Banner from '../Components/Banner/Banner';
import Footer from '../Components/Footer/Footer';
import ArticlesPage from '../Pages/ArticlesPage/ArticlesPage';
import CartProvider from '../Components/Cart/CartContext';

function App() {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setAuthModalOpen(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  return (
    <CartProvider>
      <Router>
        <div className="App">
          <Navbar onLoginClick={() => setAuthModalOpen(true)} />
          <Banner />
          <AuthModal isOpen={isAuthModalOpen} onClose={() => setAuthModalOpen(false)} />
          <div className="content-wrapper">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/resources" element={<ResourcePage />} />
              <Route path="/resources/articles/*" element={<ArticlesPage />} />
              <Route path="/onboarding" element={<OnboardingWizard />} />
              <Route path="/profile" element={<UserProfile />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
