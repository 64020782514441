// src/Pages/UserProfile.js

import React, { useEffect, useState } from 'react';
import {
  onAuthStateChanged,
  updateProfile,
  reload,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import {
  ref,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage';
import Navbar from '../../Components/Navbar/Navbar';
import './UserProfile.css';

// Import Firebase services from your initialized app
import { auth, firestore, storage } from '../../firebase';

const UserProfile = () => {
  const [userData, setUserData] = useState({
    name: '',
    role: '',
    email: '',
    phone: '',
    address: '',
    bio: '',
    experience: '',
    interests: [],
    photoURL: '',
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profileImage, setProfileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const docRef = doc(firestore, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setUserData({ ...docSnap.data(), email: user.email });
          } else {
            // If no user data exists, create a new document
            const initialData = {
              name: user.displayName || '',
              role: '',
              email: user.email,
              phone: '',
              address: '',
              bio: '',
              experience: '',
              interests: [],
              photoURL: user.photoURL || '',
              createdAt: new Date(),
            };
            await setDoc(docRef, initialData);
            setUserData(initialData);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        // User is signed out
        setUserData(null);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (field, value) => {
    setUserData((prev) => ({ ...prev, [field]: value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        let photoURL = userData.photoURL;

        // Upload new profile image if available
        if (profileImage) {
          const imageRef = ref(storage, `profileImages/${user.uid}/profile.jpg`);
          console.log('Uploading to:', imageRef.fullPath);
          await uploadBytes(imageRef, profileImage);
          photoURL = await getDownloadURL(imageRef);
        }

        // Prepare data to update
        const dataToUpdate = {
          ...userData,
          photoURL: photoURL || '',
        };

        // Update user data in Firestore using setDoc with merge
        const userDocRef = doc(firestore, 'users', user.uid);
        await setDoc(userDocRef, dataToUpdate, { merge: true });

        // Update auth.currentUser profile
        await updateProfile(user, {
          displayName: dataToUpdate.name || user.displayName,
          photoURL: dataToUpdate.photoURL || user.photoURL,
        });

        // Reload user to get updated data
        await reload(user);

        // Update local state with new data
        setUserData((prev) => ({
          ...prev,
          name: user.displayName,
          photoURL: user.photoURL,
        }));

        alert('Profile updated successfully!');
        setIsEditing(false);
        setProfileImage(null);
        setPreviewImage(null);
      } else {
        alert('You must be signed in to update your profile.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <div className="profile-page">
        <Navbar />
        <div className="profile-container">
          <div>Loading...</div>
        </div>
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="profile-page">
        <Navbar />
        <div className="profile-container">
          <p>Please log in to view your profile.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <Navbar />

      <div className="profile-container">
        <div className="profile-header">
          <div className="profile-pic">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              disabled={!isEditing}
              style={{ display: 'none' }}
              id="profileImageInput"
            />
            <label htmlFor="profileImageInput">
              {previewImage || userData.photoURL ? (
                <img
                  src={previewImage || userData.photoURL}
                  alt="Profile"
                  className="profile-pic-img"
                />
              ) : (
                <div className="profile-pic-placeholder"></div>
              )}
            </label>
          </div>
          <div className="profile-info">
            <h2
              contentEditable={isEditing}
              suppressContentEditableWarning
              onBlur={(e) => handleInputChange('name', e.target.innerText)}
            >
              {userData.name || 'Username'}
            </h2>
            <p
              contentEditable={isEditing}
              suppressContentEditableWarning
              onBlur={(e) => handleInputChange('role', e.target.innerText)}
            >
              {userData.role || 'Role'}
            </p>
            <p>{userData.email || 'Email not provided'}</p>
            <a href={`mailto:${userData.email}`}>Contact Information</a>
          </div>
        </div>

        <div className="profile-actions">
          <button onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel' : 'Edit Profile'}
          </button>
          {isEditing && (
            <button onClick={handleSave} className="save-button">
              Save
            </button>
          )}
        </div>

        <div className="profile-details">
          <div className="about-section">
            <h3>About</h3>
            <textarea
              value={userData.bio}
              onChange={(e) => handleInputChange('bio', e.target.value)}
              disabled={!isEditing}
              placeholder="Tell us about yourself..."
            />
          </div>

          <div className="additional-info">
            <p>
              <strong>Phone:</strong>
              <input
                type="text"
                value={userData.phone}
                onChange={(e) => handleInputChange('phone', e.target.value)}
                disabled={!isEditing}
                placeholder="Phone number"
              />
            </p>
            <p>
              <strong>Address:</strong>
              <input
                type="text"
                value={userData.address}
                onChange={(e) => handleInputChange('address', e.target.value)}
                disabled={!isEditing}
                placeholder="Address"
              />
            </p>
            <p>
              <strong>Experience Level:</strong>
              <input
                type="text"
                value={userData.experience}
                onChange={(e) => handleInputChange('experience', e.target.value)}
                disabled={!isEditing}
                placeholder="Experience"
              />
            </p>
            <p>
              <strong>Interests:</strong>
              <input
                type="text"
                value={userData.interests ? userData.interests.join(', ') : ''}
                onChange={(e) =>
                  handleInputChange(
                    'interests',
                    e.target.value.split(',').map((item) => item.trim())
                  )
                }
                disabled={!isEditing}
                placeholder="Comma-separated interests"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
