import React from 'react';
import hopsBeerImage from '../../assets/images/hopsbeer01.png'; // Adjust the path if necessary
import './Investment.css';

const Investment = () => {
  return (
    <div className="additional-image-container">
      <img src={hopsBeerImage} alt="Hops Beer" className="additional-image" />
    </div>
  );
};

export default Investment;
