import React from 'react';
import landingImage from '../../assets/images/landing03.png'; // Adjust the path if necessary
import './Headline.css';
import Logomation from '../Logomation/Logomation'; // Import Logomation

const Headline = () => {
  return (
    <div className="hero-section">
      <img src={landingImage} alt="Landing Background" className="hero-image" />
      <div className="hero-content">
          <Logomation />
      </div>
    </div>
  );
};

export default Headline;
