// src/Components/Logomation/Logomation.js
import React, { useState, useEffect } from 'react';
import './Logomation.css';
import logoImage from '../../assets/images/logo-transparent.png'; // Adjust the path if necessary

const Logomation = () => {
  const [stage, setStage] = useState(1); // Tracks the current stage of the animation
  const text = 'Grape Ale Project';

  useEffect(() => {
    // Animation timings in milliseconds
    const letterAnimationDelay = 100; // Delay between letters
    const totalTextAnimationDuration = text.length * letterAnimationDelay; // Time for text to appear

    const textDisplayDuration = 2000; // Time the text stays fully visible before rotation
    const rotationDuration = 2000; // Duration for rotation animations
    const logoDisplayDuration = 3000; // Time the logo stays fully visible before exploding out
    const explodeDuration = 2000; // Duration of the explode out animation
    const sloganDisplayDuration = 1000; // Duration the slogan stays visible

    // Stage transitions
    const timers = [];
    let accumulatedTime = 0;

    // Stage 2: Company name stays on screen
    accumulatedTime += totalTextAnimationDuration;
    timers.push(
      setTimeout(() => {
        setStage(2);
      }, accumulatedTime)
    );

    // Stage 3: Company name rotates out, logo rotates in
    accumulatedTime += textDisplayDuration;
    timers.push(
      setTimeout(() => {
        setStage(3);
      }, accumulatedTime)
    );

    // Stage 4: Logo stays on screen
    accumulatedTime += rotationDuration;
    timers.push(
      setTimeout(() => {
        setStage(4);
      }, accumulatedTime)
    );

    // Stage 5: Logo explodes out, slogan flashes in
    accumulatedTime += logoDisplayDuration;
    timers.push(
      setTimeout(() => {
        setStage(5);
      }, accumulatedTime)
    );

    // Stage 6: Slogan stays on screen
    accumulatedTime += explodeDuration;
    timers.push(
      setTimeout(() => {
        setStage(6);
      }, accumulatedTime)
    );

    // Loop back to Stage 1 after the slogan display duration
    accumulatedTime += sloganDisplayDuration;
    timers.push(
      setTimeout(() => {
        setStage(1);
      }, accumulatedTime)
    );

    // Cleanup on unmount
    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [text.length]);

  return (
    <div className="logomation-container">
      {/* Animation Wrapper */}
      <div className="animation-wrapper">
        {/* Company Name */}
        {(stage === 1 || stage === 2 || stage === 3) && (
          <h1 className={`animated-text ${stage === 3 ? 'rotate-out' : ''}`}>
            {text.split('').map((char, index) => (
              <span
                key={index}
                style={{
                  animationDelay: `${index * 0.1}s`,
                }}
              >
                {char === ' ' ? '\u00A0' : char}
              </span>
            ))}
          </h1>
        )}

        {/* Logo */}
        {(stage === 3 || stage === 4 || stage === 5) && (
          <img
            src={logoImage}
            alt="Logo"
            className={`logo-image ${
              stage === 3
                ? 'rotate-in'
                : stage === 5
                ? 'explode-out'
                : ''
            }`}
          />
        )}

        {/* Slogan */}
        {(stage === 5 || stage === 6) && (
          <span
            className={`brand-slogan ${stage === 5 ? 'flash-in' : ''}`}
          >
            Crafting Unique Flavors
          </span>
        )}
      </div>
    </div>
  );
};

export default Logomation;
