// src/Pages/OnboardingWizard.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Ensure you export firestore correctly from your firebase config
import './OnboardingWizard.css';

const initialData = {
  phone: '',
  address: '',
  role: '',
  experience: '',
  interests: [],
  bio: '',
};

const OnboardingWizard = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(initialData);
  const navigate = useNavigate();
  const auth = getAuth();

  const updateData = (fields) => {
    setData((prev) => ({ ...prev, ...fields }));
  };

  const handleNext = () => setStep((prev) => Math.min(prev + 1, 3));
  const handlePrev = () => setStep((prev) => Math.max(prev - 1, 1));

  const handleSubmit = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        await setDoc(userDocRef, data, { merge: true });
        alert('Onboarding data submitted successfully!');
        navigate('/profile');
        onClose();
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Failed to submit data. Please try again.');
    }
  };

  return (
    <div className="onboarding-modal">
      <div className="wizard-container">
        {/* Progress Bar */}
        <div className="progress-bar">
          {[1, 2, 3].map((num) => (
            <div
              key={num}
              className={`progress-step ${num <= step ? 'active' : ''}`}
            ></div>
          ))}
        </div>
        <p className="step-count">Step {step} of 3</p>

        {/* Step 1: Contact Information */}
        {step === 1 && (
          <div className="step">
            <h2>Contact Information</h2>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                id="phone"
                value={data.phone}
                onChange={(e) => updateData({ phone: e.target.value })}
                placeholder="Enter your phone number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                id="address"
                value={data.address}
                onChange={(e) => updateData({ address: e.target.value })}
                placeholder="Enter your address"
              />
            </div>
            <div className="button-group">
              <button onClick={handlePrev} disabled={step === 1}>
                Previous
              </button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        )}

        {/* Step 2: Professional Information */}
        {step === 2 && (
          <div className="step">
            <h2>Professional Information</h2>
            <div className="form-group">
              <label htmlFor="role">Role</label>
              <select
                id="role"
                value={data.role}
                onChange={(e) => updateData({ role: e.target.value })}
              >
                <option value="">Select your role</option>
                <option value="developer">Developer</option>
                <option value="designer">Designer</option>
                <option value="product-manager">Product Manager</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label>Experience Level</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="experience"
                    value="junior"
                    checked={data.experience === 'junior'}
                    onChange={(e) => updateData({ experience: e.target.value })}
                  />
                  Junior
                </label>
                <label>
                  <input
                    type="radio"
                    name="experience"
                    value="mid-level"
                    checked={data.experience === 'mid-level'}
                    onChange={(e) => updateData({ experience: e.target.value })}
                  />
                  Mid-level
                </label>
                <label>
                  <input
                    type="radio"
                    name="experience"
                    value="senior"
                    checked={data.experience === 'senior'}
                    onChange={(e) => updateData({ experience: e.target.value })}
                  />
                  Senior
                </label>
              </div>
            </div>
            <div className="button-group">
              <button onClick={handlePrev}>Previous</button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        )}

        {/* Step 3: Bio */}
        {step === 3 && (
          <div className="step">
            <h2>Bio</h2>
            <div className="form-group">
              <label htmlFor="bio">Tell us about yourself</label>
              <textarea
                id="bio"
                value={data.bio}
                onChange={(e) => updateData({ bio: e.target.value })}
                placeholder="Write a short bio..."
                rows={4}
              ></textarea>
            </div>
            <div className="button-group">
              <button onClick={handlePrev}>Previous</button>
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingWizard;
