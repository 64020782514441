import React from 'react';
import Banner from '../Banner/Banner'; // Ensure correct path
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      {/* Add Logo in background */}
      
      {/* Banner component at the top */}
      <Banner />
    </header>
  );
};

export default Header;
