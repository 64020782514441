// src/Components/AuthModal/AuthModal.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Import firestore
import './AuthModal.css';
import grapesImage from '../../assets/images/grapes01.png';
import { motion, AnimatePresence } from 'framer-motion';

const AuthModal = ({ isOpen, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleAuth = async () => {
    setError('');
    try {
      if (isLogin) {
        // Firebase v9 sign-in
        await signInWithEmailAndPassword(auth, email, password);
        onClose(); // Close the modal after successful login
        navigate('/profile'); // Navigate to user profile
      } else {
        // Firebase v9 sign-up
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Create a new user document in Firestore
        await setDoc(doc(firestore, 'users', user.uid), {
          name: '',
          role: '',
          email: user.email,
          phone: '',
          address: '',
          bio: '',
          experience: '',
          interests: [],
          photoURL: '',
          createdAt: new Date(),
        });

        // Optionally, update user profile
        await updateProfile(user, {
          displayName: email.split('@')[0],
          photoURL: '', // Set a default profile image URL if available
        });

        onClose(); // Close the modal after successful signup
        navigate('/profile'); // Navigate to user profile
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAuthClose = () => {
    setIsLogin(true);
    setEmail('');
    setPassword('');
    setError('');
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  // Framer Motion Variants for Modal
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.75 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.75 },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="auth-modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="auth-modal"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            <button className="auth-close-button" onClick={handleAuthClose}>
              &times;
            </button>
            <div className="modal-body">
              <img src={grapesImage} alt="Grapes" className="modal-image" />
              <div className="form-container">
                <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
                {error && <p className="error">{error}</p>}
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button className="auth-button" onClick={handleAuth}>
                  {isLogin ? 'Login' : 'Sign Up'}
                </button>
                <p className="switch-text">
                  {isLogin ? 'New here?' : 'Already have an account?'}
                  <button
                    className="switch-button"
                    onClick={() => setIsLogin(!isLogin)}
                  >
                    {isLogin ? 'Sign Up' : 'Login'}
                  </button>
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AuthModal;
