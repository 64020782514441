// src/Components/Footer/Footer.js

import React, { useState } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import NewsletterPopup from '../NewsletterPopup/NewsletterPopup';
import companyLogo from '../../assets/images/logo-transparent.png'; // Replace with your actual logo path
import corksImage from '../../assets/images/corks01.png'; // Image for the subscribe section
import { motion } from 'framer-motion'; // Import framer-motion for animation

const Footer = () => {
  const [isNewsletterOpen, setNewsletterOpen] = useState(false);

  const openNewsletter = () => {
    setNewsletterOpen(true);
  };

  const closeNewsletter = () => {
    setNewsletterOpen(false);
  };

  // Logo animation variants
  const logoVariants = {
    hover: { scale: 1.05 },
  };

  return (
    <footer className="footer">
      {/* Subscribe Section */}
      <div className="footer-subscribe">
        <img src={corksImage} alt="Corks" className="subscribe-image" />
        <div className="subscribe-content">
          <h3 className="subscribe-title">Join Our Newsletter</h3>
          <p className="subscribe-text">
            Subscribe to receive the latest updates and exclusive offers.
          </p>
          <button className="subscribe-button" onClick={openNewsletter}>
            Subscribe
          </button>
        </div>
      </div>

      {/* Black Dividing Line */}
      <hr className="footer-divider" />

      {/* Logo and Links Section */}
      <div className="footer-main">
        {/* Company Logo and Name */}
        <div className="footer-company">
          <Link to="/" className="footer-logo-link">
            <motion.img
              src={companyLogo}
              alt="Company Logo"
              className="company-logo"
              variants={logoVariants}
              whileHover="hover"
            />
          </Link>
          <Link to="/" className="company-name">
            Grape Ale Project
          </Link>
        </div>

        {/* Footer Links */}
        <div className="footer-links">
          <div className="footer-column">
            <h4>Company</h4>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/careers">Careers</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Resources</h4>
            <ul>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/support">Support</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Legal</h4>
            <ul>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
              <li><Link to="/cookie">Cookie Policy</Link></li>
            </ul>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Grape Ale Project. All rights reserved.</p>
      </div>

      {/* Newsletter Popup */}
      <NewsletterPopup isOpen={isNewsletterOpen} onClose={closeNewsletter} />
    </footer>
  );
};

export default Footer;
