// src/Pages/Articles/Article2/Article2.js

import React from 'react';

const Article2 = () => (
  <div className="article-content">
    <h2 className="modal-title">What is a Grape Ale?</h2>

    <p>
      A <strong>Grape Ale</strong> is a hybrid beverage that combines the fermentation techniques of traditional ales with the inclusion of grape juice or must, introducing distinct grape flavors and aromas into the beer profile. This fusion results in a beverage that balances the malty and hoppy characteristics of beer with the fruity, tart, or sweet notes typical of wine.
    </p>

    <h3>Key Characteristics</h3>

    <p>
      <strong>Base Ingredients:</strong> Malted barley, hops, water, yeast, and grape juice or must. <strong>Alcohol Content:</strong> Typically ranges from 5% to 8% ABV, similar to both craft beers and wines. <strong>Flavor Profile:</strong> Can vary widely depending on the grape variety used, ranging from crisp and tart to sweet and fruity.
    </p>

    <h3>History of Grape Ales</h3>

    <p>
      The concept of blending beer and wine isn't entirely new. Historically, various cultures have experimented with adding fruits, including grapes, to their brewing processes. However, the modern grape ale movement gained momentum in the late 20th and early 21st centuries, driven by the craft beer revolution and a growing interest in innovative brewing techniques.
    </p>

    <h3>Brewing Process</h3>

    <p>
      Brewing a grape ale involves several key steps, each carefully managed to balance the characteristics of both beer and wine.
    </p>

    <p>
      <strong>Mashing:</strong> Similar to traditional brewing, malted grains are mashed to extract fermentable sugars. <strong>Boiling:</strong> The wort is boiled with hops, which contribute bitterness, flavor, and aroma. <strong>Fermentation:</strong> After cooling, yeast is added to ferment the sugars into alcohol and carbon dioxide. For grape ales, some brewers incorporate grape must or juice either during primary fermentation or as a secondary addition. <strong>Aging:</strong> The beer is aged to allow flavors to meld. The addition of grapes can introduce additional fermentation complexities. <strong>Packaging:</strong> Finally, the grape ale is filtered, carbonated, and packaged for distribution.
    </p>

    <h3>Innovations in Brewing</h3>

    <p>
      <strong>Mixed Fermentation:</strong> Some brewers use wild or mixed yeast strains to enhance the complexity of the grape ale. <strong>Dry Hopping:</strong> Adding hops during or after fermentation to boost aromatic profiles without increasing bitterness.
    </p>

    <h3>Scientific Aspects of Grape Ales</h3>

    <p>
      The integration of grapes into the brewing process introduces unique scientific considerations.
    </p>

    <h3>Fermentation Dynamics</h3>

    <p>
      <strong>Yeast Activity:</strong> Grapes contain natural sugars and acids that can influence yeast performance and fermentation kinetics. Brewers must balance the yeast strains to handle both malt sugars and grape sugars effectively. <strong>pH Levels:</strong> Grapes can lower the pH of the wort, affecting enzyme activity and hop utilization during brewing.
    </p>

    <h3>Chemical Interactions</h3>

    <p>
      <strong>Phenolic Compounds:</strong> Grapes contribute tannins and other phenolic compounds that can impact mouthfeel and stability. <strong>Antioxidants:</strong> The addition of grapes introduces antioxidants, which can enhance the beer’s shelf life by preventing oxidation.
    </p>

    <h3>Microbial Stability</h3>

    <p>
      <strong>Preservation:</strong> Proper sanitation and controlled fermentation conditions are crucial to prevent unwanted microbial contamination, especially when adding fresh grape juice or must.
    </p>

    <h3>BJCP Guidelines for Grape Ales</h3>

    <p>
      The <strong>Beer Judge Certification Program (BJCP)</strong> provides style guidelines to help brewers and judges categorize and evaluate beers. While grape ales are relatively niche, they can be categorized under hybrid or specialty styles. Key considerations include:
    </p>

    <p>
      <strong>Appearance:</strong> Can range from light to deep hues, often with clarity influenced by grape addition. <strong>Aroma:</strong> Should showcase both malt and grape-derived scents, potentially including floral, fruity, or tart notes. <strong>Flavor:</strong> A harmonious balance between beer and grape flavors, avoiding overpowering any single component. <strong>Mouthfeel:</strong> Typically medium-bodied with moderate carbonation, though variations exist based on brewing techniques. <strong>Overall Impression:</strong> Should be a balanced, innovative beverage that highlights the fusion of beer and grape characteristics.
    </p>
  </div>
);

export default Article2;
