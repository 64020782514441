// src/Components/Carousel/Carousel.js

import React, { useState, useRef, useEffect, useCallback } from 'react';
import './Carousel.css';
import { Link } from 'react-router-dom';

// Import images
import vBarrelsImage from '../../assets/images/vbarrels01.png';
import vBeerImage from '../../assets/images/vbeer02.png';
import vBottlingImage from '../../assets/images/vbottling01.png';
import vCorksImage from '../../assets/images/vcorks01.png';
import vWineImage from '../../assets/images/vwine01.png';
import vFieldImage from '../../assets/images/vfield01.png';
import vGrapeImage from '../../assets/images/vgrape01.png';
import vHopsImage from '../../assets/images/vhops01.png';
import vKegsImage from '../../assets/images/vkegs01.png';
import vTapsImage from '../../assets/images/vtaps01.png';

const Carousel = () => {
  const images = [
    {
      src: vBarrelsImage,
      alt: 'Barrels',
      link: '/resources/articles#article1',
      title: 'Quality Barrels',
      description: 'Learn about our high-quality barrels used in the aging process.',
    },
    {
      src: vBeerImage,
      alt: 'Beer',
      link: '/resources/articles#article2',
      title: 'Our Beers',
      description: 'Discover the different types of beer we produce.',
    },
    {
      src: vBottlingImage,
      alt: 'Bottling',
      link: '/resources/articles#article3',
      title: 'Bottling Process',
      description: 'Explore our bottling process ensuring quality and freshness.',
    },
    {
      src: vCorksImage,
      alt: 'Corks',
      link: '/resources/articles#article4',
      title: 'The Importance of Corks',
      description: 'Understand the importance of corks in preserving flavor.',
    },
    {
      src: vWineImage,
      alt: 'Wine',
      link: '/resources/articles#article5',
      title: 'Our Wines',
      description: 'Delve into our exquisite range of wines.',
    },
    {
      src: vFieldImage,
      alt: 'Field',
      link: '/resources/articles#article6',
      title: 'Our Fields',
      description: 'See where our grapes are grown in our expansive fields.',
    },
    {
      src: vGrapeImage,
      alt: 'Grapes',
      link: '/resources/articles#article7',
      title: 'Grape Varieties',
      description: 'Learn about the varieties of grapes we cultivate.',
    },
    {
      src: vHopsImage,
      alt: 'Hops',
      link: '/resources/articles#article8',
      title: 'The Role of Hops',
      description: 'Discover how hops contribute to the flavor of our beers.',
    },
    {
      src: vKegsImage,
      alt: 'Kegs',
      link: '/resources/articles#article9',
      title: 'Kegging Process',
      description: 'Explore our kegging process for draft beverages.',
    },
    {
      src: vTapsImage,
      alt: 'Taps',
      link: '/resources/articles#article10',
      title: 'Tap Systems',
      description: 'Understand the mechanics behind our tap systems.',
    },
  ];

  const carouselRef = useRef(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const clonedImages = [...images.slice(-4), ...images, ...images.slice(0, 4)];
  const totalImages = clonedImages.length;
  const visibleImages = 4;

  const scrollToIndex = useCallback(
    (index) => {
      const carousel = carouselRef.current;
      if (carousel) {
        const itemWidth = carousel.scrollWidth / totalImages;
        carousel.scrollTo({
          left: itemWidth * index,
          behavior: 'smooth',
        });
      }
    },
    [totalImages]
  );

  useEffect(() => {
    scrollToIndex(visibleImages);
    setActiveIndex(visibleImages);
  }, [scrollToIndex, visibleImages]);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const handleScroll = () => {
      const { scrollLeft, scrollWidth } = carousel;
      const itemWidth = scrollWidth / totalImages;
      let currentIndex = Math.round(scrollLeft / itemWidth);
      setActiveIndex(currentIndex);

      if (currentIndex <= 3) {
        carousel.scrollLeft = itemWidth * (images.length + 3);
        setActiveIndex(images.length + 3);
      } else if (currentIndex >= images.length + 4) {
        carousel.scrollLeft = itemWidth * 4;
        setActiveIndex(4);
      }
    };

    carousel.addEventListener('scroll', handleScroll);
    return () => carousel.removeEventListener('scroll', handleScroll);
  }, [images.length, totalImages]);

  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    carouselRef.current.classList.add('active');
    startXRef.current = e.pageX - carouselRef.current.offsetLeft;
    scrollLeftRef.current = carouselRef.current.scrollLeft;
  };

  const handleMouseLeave = () => {
    isDraggingRef.current = false;
    carouselRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    carouselRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startXRef.current) * 2;
    carouselRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  return (
    <>
      <div
        className="photo-carousel"
        ref={carouselRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {clonedImages.map((image, index) => (
          <div key={index} className="carousel-image-container">
            <Link to={image.link} className="carousel-image-link">
              <img src={image.src} alt={image.alt} className="carousel-image" />
              <div className="carousel-overlay">
                <h3 className="overlay-title">{image.title}</h3>
                <p className="overlay-description">{image.description}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${activeIndex === index + visibleImages ? 'active' : ''}`}
            onClick={() => scrollToIndex(index + visibleImages)}
          ></div>
        ))}
      </div>
    </>
  );
};

export default Carousel;
