// src/Pages/ShopPage.js
import React from 'react';
import './ShopPage.css'; // The stylesheet for this page
import { useCart } from '../../Components/Cart/CartContext'; // Import useCart hook
import { toast } from 'react-toastify'; // Import toast for notifications

const Shop = () => {
  const { addToCart } = useCart(); // Destructure addToCart from context

  // Corrected data for categories and products using require for images
  const categories = [
    { name: 'Best Sellers', image: require('../../assets/images/beerglass01.png') },
    { name: 'Mixed Packs', image: require('../../assets/images/beerglass01.png') },
    { name: 'Alcohol Free', image: require('../../assets/images/beerglass01.png') },
    { name: 'Limited Edition', image: require('../../assets/images/beerglass01.png') },
    { name: 'New In', image: require('../../assets/images/beerglass01.png') },
    { name: 'Gifts', image: require('../../assets/images/beerglass01.png') },
    { name: 'Clearance', image: require('../../assets/images/beerglass01.png') }
  ];

  const products = [
    { name: 'Gap Light', image: require('../../assets/images/beerglass01.png'), tag: 'Best Seller', price: 25 },
    { name: 'Gap Rosso', image: require('../../assets/images/beerglass01.png'), tag: 'Best Seller', price: 30 },
    { name: 'Gap Red', image: require('../../assets/images/beerglass01.png'), tag: 'Best Seller', price: 35 },
    // Add more product entries as needed
  ];

  // Handler for adding items to the cart
  const handleAddToCart = (product) => {
    addToCart(product); // Add product to cart
    toast.success(`${product.name} added to cart!`); // Show success toast
  };

  return (
    <div className="shop-page">
      <header className="shop-header">
        <h1>Shop All Beer</h1>
        <nav className="breadcrumb">
          <a href="/">Home</a> / <span>Shop All Beer</span>
        </nav>
      </header>

      {/* Categories Section */}
      <section className="categories">
        <div className="categories-container">
          {categories.map((category, index) => (
            <div className="category-item" key={index}>
              <img src={category.image} alt={category.name} />
              <span>{category.name}</span>
            </div>
          ))}
        </div>
      </section>

      {/* Product Grid Section */}
      <section className="product-grid">
        <div className="product-grid-header">
          <span>{products.length} ITEMS</span>
          <div className="filters">
            <select>
              <option>Style</option>
              <option>IPA</option>
              <option>Stout</option>
              <option>Lager</option>
              {/* Add more filter options */}
            </select>
            <select>
              <option>ABV</option>
              <option>4-5%</option>
              <option>5-6%</option>
              <option>6%+</option>
              {/* Add more filter options */}
            </select>
            <select>
              <option>Sort: Most Popular</option>
              <option>Sort: Price Low to High</option>
              <option>Sort: Price High to Low</option>
              <option>Sort: Newest</option>
              {/* Add more sorting options */}
            </select>
          </div>
        </div>

        <div className="products-container">
          {products.map((product, index) => (
            <div className="product-item" key={index}>
              <span className="product-tag">{product.tag}</span>
              <img src={product.image} alt={product.name} />
              <span className="product-name">{product.name}</span>
              <span className="product-price">${product.price}</span>
              <button onClick={() => handleAddToCart(product)} className="add-to-cart-button">
                Add to Cart
              </button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Shop;
