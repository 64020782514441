// src/Components/Cart/CartContext.js
import React, { createContext, useReducer, useContext } from 'react';

// Create the context
const CartContext = createContext();

// Initial state
const initialState = {
  items: [],
  isAnimating: false, // For animation control
};

// Reducer to manage cart state
const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItemIndex = state.items.findIndex(item => item.name === action.payload.name);
      let updatedItems = [];

      if (existingItemIndex > -1) {
        // Item exists, increment quantity
        updatedItems = state.items.map((item, index) =>
          index === existingItemIndex ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        // Add new item
        updatedItems = [...state.items, { ...action.payload, quantity: 1 }];
      }

      return {
        ...state,
        items: updatedItems,
        isAnimating: true, // Trigger animation
      };

    case 'REMOVE_FROM_CART':
      return {
        ...state,
        items: state.items.filter(item => item.name !== action.payload.name),
      };

    case 'CLEAR_CART':
      return {
        ...state,
        items: [],
      };

    case 'STOP_ANIMATION':
      return {
        ...state,
        isAnimating: false,
      };

    default:
      return state;
  }
};

// CartProvider to wrap components and provide cart state
const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  // Actions
  const addToCart = (product) => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  const removeFromCart = (product) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: product });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  const stopAnimation = () => {
    dispatch({ type: 'STOP_ANIMATION' });
  };

  return (
    <CartContext.Provider value={{ cart: state, addToCart, removeFromCart, clearCart, stopAnimation }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use cart context
export const useCart = () => {
  return useContext(CartContext);
};

export default CartProvider;
