import React, { useEffect, useState } from 'react';
import { firestore } from '../../firebase'; // Adjust the path to your firebase config
import './DataTable.css'; // CSS for styling

const DataTable = () => {
    const [beers, setBeers] = useState([]);

    // Fetch data from Firestore
    useEffect(() => {
        const fetchBeers = async () => {
            try {
                const snapshot = await firestore.collection('grapeBeers').get();
                const beerData = snapshot.docs.map(doc => doc.data());
                setBeers(beerData);
            } catch (error) {
                console.error("Error fetching beer data:", error);
            }
        };
        fetchBeers();
    }, []);

    return (
        <div className="data-table-container">
            <h2>Grape Ales Data</h2>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Brewery</th>
                        <th>ABV</th>
                        <th>Style</th>
                        <th>Ingredients</th>
                        <th>Location</th>
                        <th>Mostly Consumed</th>
                    </tr>
                </thead>
                <tbody>
                    {beers.map((beer, index) => (
                        <tr key={index}>
                            <td>{beer.name}</td>
                            <td>{beer.brewery}</td>
                            <td>{beer.abv}</td>
                            <td>{beer.style}</td>
                            <td>{beer.ingredients}</td>
                            <td>{beer.location}</td>
                            <td>{beer.mostlyConsumed}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DataTable;
