// src/Pages/HomePage.js

import React from 'react';
import Header from '../../Components/Header/Header';
import Headline from '../../Components/Headline/Headline';
import Carousel from '../../Components/Carousel/Carousel';
import Investment from '../../Components/Investment/Investment';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="homepage">
      <Header />
      <div className="content-container">
        <Headline />
        <Carousel />
        <Investment />
      </div>
    </div>
  );
};

export default HomePage;
