// src/Components/NewsletterPopup/NewsletterPopup.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './NewsletterPopup.css';
import { subscribeToNewsletter } from '../../firebase';
import vbeerImage from '../../assets/images/vbeer01.png'; // Updated image for the popup

const NewsletterPopup = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await subscribeToNewsletter(email);
    if (success) {
      setMessage('Thank you for subscribing!');
      setEmail('');
    } else {
      setMessage('Failed to subscribe. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="newsletter-popup-overlay">
      <div className="newsletter-popup">
        <button className="close-button" onClick={onClose}>×</button>
        <div className="popup-content">
          <img src={vbeerImage} alt="Beer" className="corks-image" /> {/* Updated image */}
          <div className="form-content">
            <h2 className="popup-title">Subscribe to the newsletter</h2>
            <p className="popup-text">Stay up to date on Grape Ale Project news and articles.</p>
            <form onSubmit={handleSubmit} className="newsletter-form">
              <input
                type="email"
                className="email-input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="submit-button">Sign up</button>
            </form>
            {message && <p className="form-message">{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

NewsletterPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewsletterPopup;
