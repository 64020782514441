// src/Components/Dropdown/Dropdown.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  onIdTokenChanged,
  signOut,
} from 'firebase/auth';
import './Dropdown.css';
import {
  FaUserCircle,
  FaCog,
  FaSignOutAlt,
  FaExchangeAlt,
  FaSignInAlt,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const Dropdown = ({ onLoginClick }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    // Listen for ID token changes (including profile updates)
    const unsubscribe = onIdTokenChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setIsAuthenticated(false);
      navigate('/'); // Redirect to home after sign out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Framer Motion Variants
  const authItemVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
  };

  const authItemHover = {
    scale: 1.05,
    backgroundColor: 'rgba(255, 255, 255, 0.3)', // Glassy effect
    backdropFilter: 'blur(10px)',
    transition: { type: 'spring', stiffness: 300 },
  };

  // Function to render auth content based on authentication
  const renderAuthContent = () => {
    if (!isAuthenticated) {
      return (
        <motion.button
          className="sidebar-item login-button"
          variants={authItemVariants}
          whileHover={authItemHover}
          onClick={() => {
            onLoginClick();
          }}
        >
          <FaSignInAlt className="icon" /> Login/Signup
        </motion.button>
      );
    }

    return (
      <>
        <motion.button
          className="sidebar-item"
          variants={authItemVariants}
          whileHover={authItemHover}
          onClick={() => {
            navigate('/profile');
            // Assuming you have access to setIsSidebarOpen, else handle it in Navbar.js
          }}
        >
          <FaUserCircle className="icon" /> User Profile
        </motion.button>
        <motion.button
          className="sidebar-item"
          variants={authItemVariants}
          whileHover={authItemHover}
          onClick={() => {
            navigate('/trade');
            // Assuming you have access to setIsSidebarOpen, else handle it in Navbar.js
          }}
        >
          <FaExchangeAlt className="icon" /> Trade
        </motion.button>
        <motion.button
          className="sidebar-item"
          variants={authItemVariants}
          whileHover={authItemHover}
          onClick={() => {
            navigate('/settings');
            // Assuming you have access to setIsSidebarOpen, else handle it in Navbar.js
          }}
        >
          <FaCog className="icon" /> Settings
        </motion.button>
        <motion.button
          className="sidebar-item sign-out-button"
          variants={authItemVariants}
          whileHover={authItemHover}
          onClick={handleSignOut}
        >
          <FaSignOutAlt className="icon" /> Sign Out
        </motion.button>
      </>
    );
  };

  return <>{renderAuthContent()}</>;
};

Dropdown.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
};

export default Dropdown;
