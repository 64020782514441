// src/Pages/ArticlesPage/ArticlesPage.js

import React, { useState, useEffect } from 'react';
import './ArticlesPage.css';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

// Importing Articles
import Article1 from '../Articles/Article1/Article1';
import Article2 from '../Articles/Article2/Article2';
import Article3 from '../Articles/Article3/Article3';
import Article4 from '../Articles/Article4/Article4';
import Article5 from '../Articles/Article5/Article5';
import Article6 from '../Articles/Article6/Article6';
import Article7 from '../Articles/Article7/Article7';
import Article8 from '../Articles/Article8/Article8';
import Article9 from '../Articles/Article9/Article9';
import Article10 from '../Articles/Article10/Article10';

// Importing Images
import vBarrelsImage from '../../assets/images/pop-barrels01.png';
import vBeerImage from '../../assets/images/pop-beer02.png';
import vBottlingImage from '../../assets/images/vbottling01.png';
import vCorksImage from '../../assets/images/vcorks01.png';
import vWineImage from '../../assets/images/vwine01.png';
import vFieldImage from '../../assets/images/vfield01.png';
import vGrapeImage from '../../assets/images/vgrape01.png';
import vHopsImage from '../../assets/images/vhops01.png';
import vKegsImage from '../../assets/images/vkegs01.png';
import vTapsImage from '../../assets/images/vtaps01.png';

const articles = [
  {
    id: 'article1',
    src: vBarrelsImage,
    alt: 'Barrels',
    title: 'Quality Barrels',
    description: 'Learn about our high-quality barrels used in the aging process.',
    component: <Article1 />,
  },
  {
    id: 'article2',
    src: vBeerImage,
    alt: 'Beer',
    title: 'What is a Grape Ale?',
    description: 'Exploring the Fusion of Beer and Wine',
    component: <Article2 />,
  },
  {
    id: 'article3',
    src: vBottlingImage,
    alt: 'Bottling',
    title: 'Bottling Process',
    description: 'Explore our bottling process ensuring quality and freshness.',
    component: <Article3 />,
  },
  {
    id: 'article4',
    src: vCorksImage,
    alt: 'Corks',
    title: 'The Importance of Corks',
    description: 'Understand the importance of corks in preserving flavor.',
    component: <Article4 />,
  },
  {
    id: 'article5',
    src: vWineImage,
    alt: 'Wine',
    title: 'Our Wines',
    description: 'Delve into our exquisite range of wines.',
    component: <Article5 />,
  },
  {
    id: 'article6',
    src: vFieldImage,
    alt: 'Field',
    title: 'Our Fields',
    description: 'See where our grapes are grown in our expansive fields.',
    component: <Article6 />,
  },
  {
    id: 'article7',
    src: vGrapeImage,
    alt: 'Grapes',
    title: 'Grape Varieties',
    description: 'Learn about the varieties of grapes we cultivate.',
    component: <Article7 />,
  },
  {
    id: 'article8',
    src: vHopsImage,
    alt: 'Hops',
    title: 'The Role of Hops',
    description: 'Discover how hops contribute to the flavor of our beers.',
    component: <Article8 />,
  },
  {
    id: 'article9',
    src: vKegsImage,
    alt: 'Kegs',
    title: 'Kegging Process',
    description: 'Explore our kegging process for draft beverages.',
    component: <Article9 />,
  },
  {
    id: 'article10',
    src: vTapsImage,
    alt: 'Taps',
    title: 'Tap Systems',
    description: 'Understand the mechanics behind our tap systems.',
    component: <Article10 />,
  },
];

const ArticlesPage = () => {
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  // Import useLocation to access URL hash
  const location = useLocation();

  const openModal = (article) => {
    setModalContent(article);
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    document.body.style.overflow = 'auto'; // Re-enable scrolling when modal is closed
  };

  // Handle Esc key to close modal
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape' && isModalOpen) {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isModalOpen]);

  // Scroll-triggered animations for text content and scroll progress
  useEffect(() => {
    if (isModalOpen && modalContent) {
      const modalContentEl = document.querySelector('.modal-text-content');
      if (modalContentEl) {
        // Reset scroll position to top
        modalContentEl.scrollTop = 0;
      }

      const textElements = modalContentEl.querySelectorAll('p, h2, h3, h4');

      // Remove 'show' class from text elements
      textElements.forEach((el) => {
        el.classList.remove('show');
      });

      const options = {
        root: modalContentEl,
        rootMargin: '0px',
        threshold: 0.1,
      };

      const callback = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target);
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);

      textElements.forEach((el) => {
        observer.observe(el);
      });

      const handleScroll = () => {
        const scrollTop = modalContentEl.scrollTop;
        const scrollHeight =
          modalContentEl.scrollHeight - modalContentEl.clientHeight;
        const progress = (scrollTop / scrollHeight) * 100;
        setScrollProgress(progress);
      };

      modalContentEl.addEventListener('scroll', handleScroll);

      return () => {
        observer.disconnect();
        modalContentEl.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isModalOpen, modalContent]);

  // Open modal if URL hash is present
  useEffect(() => {
    if (location.hash) {
      const articleId = location.hash.substring(1); // Remove '#' from the hash
      const article = articles.find((a) => a.id === articleId);
      if (article) {
        openModal(article);
      }
    }
  }, [location]);

  // Function to get the next article
  const getNextArticle = (currentId) => {
    const currentIndex = articles.findIndex((article) => article.id === currentId);
    const nextIndex = (currentIndex + 1) % articles.length;
    return articles[nextIndex];
  };

  // Function to get the previous article
  const getPreviousArticle = (currentId) => {
    const currentIndex = articles.findIndex((article) => article.id === currentId);
    const prevIndex = (currentIndex - 1 + articles.length) % articles.length;
    return articles[prevIndex];
  };

  return (
    <div className="articles-page">
      <h1>Our Articles</h1>
      <div className="articles-grid">
        {articles.map((article, index) => (
          <motion.div
            key={article.id}
            className="article-card"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
            onClick={() => openModal(article)}
          >
            <img src={article.src} alt={article.alt} className="article-image" />
            <div className="article-content">
              <h2>{article.title}</h2>
              <p>{article.description}</p>
            </div>
          </motion.div>
        ))}
      </div>

      {isModalOpen && modalContent && (
        <div
          className="modal-overlay"
          onClick={closeModal}
          role="dialog"
          aria-modal="true"
        >
          <div
            className={`modal-content ${modalContent.id === 'article5' ? 'article5-modal' : ''}`}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close-button"
              onClick={closeModal}
              aria-label="Close Modal"
            >
              &times;
            </button>
            <div
              className="modal-background"
              style={{
                backgroundImage: `url(${modalContent.src})`,
              }}
            ></div>
            {/* Dotted Scrollbar */}
            <div className="dotted-scrollbar">
              <div
                className="dots-container"
                style={{ '--scroll-progress': `${scrollProgress}%` }}
              >
                {Array.from({ length: 15 }).map((_, index) => (
                  <div
                    key={index}
                    className={`dot ${
                      scrollProgress >= (index / 14) * 100 ? 'active' : ''
                    }`}
                  ></div>
                ))}
              </div>
            </div>
            <div className="modal-text-content">
              {modalContent.component}
              {/* Article Navigation */}
              <div className="article-navigation">
                {/* Previous Article Section */}
                <div className="previous-article-section">
                  <h3>Previous Article</h3>
                  <div
                    className="previous-article-card"
                    onClick={() => openModal(getPreviousArticle(modalContent.id))}
                  >
                    <img
                      src={getPreviousArticle(modalContent.id).src}
                      alt={getPreviousArticle(modalContent.id).alt}
                    />
                    <div>
                      <h4>{getPreviousArticle(modalContent.id).title}</h4>
                      <p>{getPreviousArticle(modalContent.id).description}</p>
                    </div>
                  </div>
                </div>
                {/* Next Article Section */}
                <div className="next-article-section">
                  <h3>Next Article</h3>
                  <div
                    className="next-article-card"
                    onClick={() => openModal(getNextArticle(modalContent.id))}
                  >
                    <img
                      src={getNextArticle(modalContent.id).src}
                      alt={getNextArticle(modalContent.id).alt}
                    />
                    <div>
                      <h4>{getNextArticle(modalContent.id).title}</h4>
                      <p>{getNextArticle(modalContent.id).description}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End of Article Navigation */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlesPage;
