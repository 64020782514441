// src/Components/Navbar/Navbar.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/images/logo-transparent.png';
import {
  FaUserCircle,
  FaBars,
  FaShoppingCart,
  FaBeer,
  FaTshirt,
  FaTruck,
  FaCalendarAlt,
  FaChartLine,
  FaHammer,
  FaBookOpen,
  FaNewspaper,
  FaInfoCircle,
  FaEnvelope,
  FaHandsHelping,
  FaUsers,
  FaExchangeAlt,
  FaCog,
  FaSignOutAlt,
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useCart } from '../Cart/CartContext';
import Dropdown from '../Dropdown/Dropdown';
import { auth } from '../../firebase';
import { onIdTokenChanged, signOut } from 'firebase/auth';

const Navbar = ({ onLoginClick }) => {
  const { cart } = useCart();

  // State to manage sidebar open/close
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // State to manage active tab in sidebar
  const [activeTab, setActiveTab] = useState('shop');

  // Authentication state
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userPhotoURL, setUserPhotoURL] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Listen for ID token changes (including profile updates)
    const unsubscribe = onIdTokenChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUserPhotoURL(user.photoURL);
      } else {
        setIsAuthenticated(false);
        setUserPhotoURL(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Handle sidebar toggle
  const toggleSidebar = (tab = null) => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
      if (tab) {
        setActiveTab(tab);
      }
    } else {
      setIsSidebarOpen(true);
      if (tab) {
        setActiveTab(tab);
      }
    }
  };

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.sidebar') &&
        !event.target.closest('.hamburger-menu') &&
        !event.target.closest('.dropdown-toggle') &&
        !event.target.closest('.profile-image') &&
        !event.target.closest('.profile-icon') &&
        !event.target.closest('.shopping-cart-icon')
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  // Framer Motion Variants
  const sidebarVariants = {
    hidden: { x: '-100%', opacity: 0 },
    visible: {
      x: '0%',
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    exit: { x: '-100%', opacity: 0 },
  };

  const sidebarItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  const sidebarItemHover = {
    scale: 1.05,
    backgroundColor: 'rgba(255, 255, 255, 0.3)', // Glassy effect
    backdropFilter: 'blur(10px)',
    transition: { type: 'spring', stiffness: 300 },
  };

  // Function to get the sidebar title based on active tab
  const getSidebarTitle = () => {
    switch (activeTab) {
      case 'shop':
        return 'Shop';
      case 'resources':
        return 'Resources';
      case 'aboutUs':
        return 'About Us';
      default:
        return '';
    }
  };

  // Function to render content based on active tab
  const renderSidebarContent = () => {
    switch (activeTab) {
      case 'shop':
        return (
          <>
            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/shop');
                setIsSidebarOpen(false);
              }}
            >
              <FaBeer className="icon" /> Beer
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/shop');
                setIsSidebarOpen(false);
              }}
            >
              <FaTshirt className="icon" /> Merch
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/shop');
                setIsSidebarOpen(false);
              }}
            >
              <FaTruck className="icon" /> Distributors
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/shop');
                setIsSidebarOpen(false);
              }}
            >
              <FaCalendarAlt className="icon" /> Events
            </motion.button>
          </>
        );
      case 'resources':
        return (
          <>
            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/resources');
                setIsSidebarOpen(false);
              }}
            >
              <FaChartLine className="icon" /> Tracker
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/resources');
                setIsSidebarOpen(false);
              }}
            >
              <FaHammer className="icon" /> Builder
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/resources');
                setIsSidebarOpen(false);
              }}
            >
              <FaBookOpen className="icon" /> Wiki
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/resources/articles');
                setIsSidebarOpen(false);
              }}
            >
              <FaNewspaper className="icon" /> Articles
            </motion.button>
          </>
        );
      case 'aboutUs':
        return (
          <>
            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/aboutus'); // Ensure this route exists
                setIsSidebarOpen(false);
              }}
            >
              <FaInfoCircle className="icon" /> Our Story
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/contactus'); // Ensure this route exists
                setIsSidebarOpen(false);
              }}
            >
              <FaEnvelope className="icon" /> Contact Us
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/supportus'); // Ensure this route exists
                setIsSidebarOpen(false);
              }}
            >
              <FaHandsHelping className="icon" /> Support Us
            </motion.button>

            <motion.button
              className="sidebar-item"
              variants={sidebarItemVariants}
              whileHover={sidebarItemHover}
              onClick={() => {
                navigate('/team'); // Ensure this route exists
                setIsSidebarOpen(false);
              }}
            >
              <FaUsers className="icon" /> The Team
            </motion.button>
          </>
        );
      default:
        return null;
    }
  };

  // Function to render authentication section
  const renderAuthContent = () => {
    if (!isAuthenticated) {
      return <Dropdown onLoginClick={onLoginClick} />;
    }

    return (
      <>
        <motion.button
          className="sidebar-item"
          variants={sidebarItemVariants}
          whileHover={sidebarItemHover}
          onClick={() => {
            navigate('/profile');
            setIsSidebarOpen(false);
          }}
        >
          <FaUserCircle className="icon" /> User Profile
        </motion.button>
        <motion.button
          className="sidebar-item"
          variants={sidebarItemVariants}
          whileHover={sidebarItemHover}
          onClick={() => {
            navigate('/trade');
            setIsSidebarOpen(false);
          }}
        >
          <FaExchangeAlt className="icon" /> Trade
        </motion.button>
        <motion.button
          className="sidebar-item"
          variants={sidebarItemVariants}
          whileHover={sidebarItemHover}
          onClick={() => {
            navigate('/settings');
            setIsSidebarOpen(false);
          }}
        >
          <FaCog className="icon" /> Settings
        </motion.button>
        <motion.button
          className="sidebar-item sign-out-button"
          variants={sidebarItemVariants}
          whileHover={sidebarItemHover}
          onClick={async () => {
            try {
              await signOut(auth);
              setIsSidebarOpen(false);
              navigate('/'); // Redirect to home after sign out
            } catch (error) {
              console.error('Error signing out:', error);
            }
          }}
        >
          <FaSignOutAlt className="icon" /> Sign Out
        </motion.button>
      </>
    );
  };

  return (
    <>
      <motion.nav className="navbar top-navbar">
        <div className="navbar-container">
          <div className="nav-left">
            {/* Hamburger Menu */}
            <div
              className="hamburger-menu"
              onClick={() => toggleSidebar()}
              aria-label="Open main menu"
              aria-expanded={isSidebarOpen}
            >
              <FaBars size={24} />
            </div>
            <Link to="/" className="nav-logo-link">
              <motion.img
                src={logo}
                alt="Company Logo"
                className="nav-logo"
                whileHover={{ scale: 1.1 }}
              />
            </Link>
          </div>

          <div className="nav-center">
            {/* Removed navigation links as per user request */}
          </div>

          <div className="nav-right">
            {/* Shopping Cart Icon */}
            <div
              className="shopping-cart-icon"
              onClick={() => navigate('/shop')}
              aria-label="Shop"
            >
              <FaShoppingCart size={24} />
              {cart.items.length > 0 && (
                <span className="shop-count">
                  {cart.items.reduce((sum, item) => sum + item.quantity, 0)}
                </span>
              )}
            </div>

            {/* Profile Icon */}
            <div
              className="dropdown-toggle"
              onClick={() => {
                if (isAuthenticated) {
                  navigate('/profile');
                  setIsSidebarOpen(false);
                } else {
                  onLoginClick();
                }
              }}
              aria-label="User menu"
              aria-expanded={isSidebarOpen}
            >
              {isAuthenticated && userPhotoURL ? (
                <img
                  src={userPhotoURL}
                  alt="User Profile"
                  className="profile-image"
                />
              ) : (
                <FaUserCircle className="profile-icon" />
              )}
            </div>
          </div>
        </div>
      </motion.nav>

      {/* Sidebar */}
      <AnimatePresence>
        {isSidebarOpen && (
          <motion.div
            className="sidebar left-sidebar"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={sidebarVariants}
          >
            <button
              className="close-button"
              onClick={() => setIsSidebarOpen(false)}
              aria-label="Close sidebar"
            >
              &times;
            </button>
            {/* Tabs at the Top of Sidebar */}
            <div className="sidebar-tabs">
              <button
                className={`sidebar-tab ${activeTab === 'shop' ? 'active' : ''}`}
                onClick={() => setActiveTab('shop')}
                aria-label="Shop"
              >
                <FaShoppingCart />
              </button>
              <button
                className={`sidebar-tab ${activeTab === 'resources' ? 'active' : ''}`}
                onClick={() => setActiveTab('resources')}
                aria-label="Resources"
              >
                <FaBookOpen />
              </button>
              <button
                className={`sidebar-tab ${activeTab === 'aboutUs' ? 'active' : ''}`}
                onClick={() => setActiveTab('aboutUs')}
                aria-label="About Us"
              >
                <FaInfoCircle />
              </button>
            </div>

            {/* Sidebar Title */}
            <h2 className="sidebar-title">{getSidebarTitle()}</h2>

            {/* Sidebar Content */}
            <div className="sidebar-content">{renderSidebarContent()}</div>

            {/* Auth Content */}
            <div className="sidebar-auth">{renderAuthContent()}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
