import React from 'react';
import DataTable from '../../Components/Table/DataTable'; // Adjust the path
import './ResourcePage.css'; // Assuming other styles for the ResourcePage

const ResourcePage = () => {
    return (
        <div className="resource-page">
            <nav className="navbar resource-navbar">
                <ul className="nav-links">
                    <li><a href="#beer-tracker">Beer Tracker</a></li>
                    <li><a href="#oenowiki">OenoWiki</a></li>
                    <li><a href="#grapes">Grapes</a></li>
                    <li><a href="#research">Research</a></li>
                </ul>
            </nav>

            <div className="content">
                <h1>Resource Page</h1>
                <section id="beer-tracker">
                    <DataTable />
                </section>
            </div>
        </div>
    );
};

export default ResourcePage;
